<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2022-12
 * @Description: 优惠券列表
-->

<template>
  <div class="coupon-main">
    <NavBar :title="$route.meta.title" left-arrow>
      <template #right>
        <span class="common-nav-bar-btn" @click="clickFaqBtn"> FAQ </span>
      </template>
    </NavBar>
    <CommonPanel
      convertBorderPosition="top"
      :showUseBtnInDetail="true"
      :couponInfo="instance.couponInfo"
      @convertSuccess="convertSuccess"
    >
      <template v-slot:footer>
        <UseTip class="tip-position" />
        <!-- flex: 1; -->
        <div style="padding-top: 0.72rem"></div>
      </template>
    </CommonPanel>
  </div>
</template>

<script setup>
import CommonPanel from '@/views/Coupon/components/CommonPanel.vue'
import UseTip from '@/views/Coupon/components/UseTip.vue'
import { reactive, onMounted, getCurrentInstance } from 'vue'
import NavBar from '@/components/NavBar.vue'
import { clearWindowHrefParams } from '@/utils/index.js'
import { JUMP_FROM } from './index.js'
import { useDarkPopup } from '@/hooks/useDarkPopup.js'
import { $t } from '@/i18n'

let _this = null

class Coupon {
  couponInfo = reactive({
    from: '',
    coupons: [],
    loadingList: false
  });

  init () {
    this.couponInfo.from = _this.$route.query.from
    this.getCoupons()
    if (JUMP_FROM.obtainCoupon === this.couponInfo.from) {
      clearWindowHrefParams()
      _this.$store.dispatch('ifShowPwdTip', { touch_type: 'receiveCoupon' })
    }
  }

  async getCoupons () {
    const { couponInfo } = this
    couponInfo.loadingList = true
    const { country_en_us: country } =
      _this.$store.state.payment.defaultCountryInfo

    const { coupons, currency } = await Apis.getCoupons({ country }).finally(
      () => (couponInfo.loadingList = false)
    )
    // 注入属性
    coupons.forEach((item) => {
      item.currency = currency
    })
    couponInfo.coupons = coupons
  }
}

const instance = new Coupon()

const convertSuccess = () => {
  instance.getCoupons()
}

function clickFaqBtn () {
  const { showPopup } = useDarkPopup()
  showPopup({
    props: {
      content: $t('couponFaq.describe')
    }
  })
}

onMounted(() => {
  const { proxy } = getCurrentInstance()
  _this = proxy
  instance.init()
})
</script>

<style lang="less" scoped>
@import url("~@/styles/common.less");

.coupon-main {
  height: calc(100vh - @navbarHeight);
}
.tip-position {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
</style>
