/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2022-11
 * @Description:
 */
import Vue from 'vue'
import DartPopup from '@/components/DartPopup.vue'

export function useDarkPopup () {
  // 弹窗实例
  let popup = null

  /**
   * @description: 挂载组件
   * @param {object} options 组件选项，例：{ props }
   * @return { showPopup }
   */
  function mountComponent (options = {}) {
    const PopupCom = Vue.extend(DartPopup)
    const popupInstance = new PopupCom({
      // 覆盖props
      propsData: options.props
    })
    document.body.appendChild(popupInstance.$mount().$el)
    popup = popupInstance
  }

  /**
   * @description: 显示弹窗
   * @param: options 同mountComponent的options
   */
  function showPopup (options) {
    if (!popup) {
      mountComponent(options)
    }
    popup.showPopup(true)
  }

  return { showPopup }
}
