<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2022-11
 * @Description:
-->
<template>
  <div>
    <van-popup class="order-popup" v-model="show" closeable>
      <div class="container" v-html="content"></div>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const show = ref(false)

const props = defineProps({
  // 支持html解析
  content: {
    type: String,
    default: ''
  }
})
function showPopup (isShow = true) {
  show.value = isShow
}

defineExpose({ showPopup })

</script>
<style scoped lang="less">
@keyColor: #f8e19d;
/deep/ .van-popup__close-icon {
  right: 8px;
}
/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

@media screen and (min-width: 750px) {
  .container {
    width: 750px;
    margin: 0 auto;
  }
}

.order-popup {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #fff;
  font-size: 22px;
}
.container {
  height: 100%;
  overflow: auto;
  padding: 88px 54px;
}
</style>
