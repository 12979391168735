<!--
 * @Date: 2022-06-02 09:52:53
 * @LastEditors: wfj
 * @LastEditTime: 2022-09
 * @FilePath: /dc-pay-front/src/components/NavBar.vue
-->

<template>
  <van-nav-bar
    ref="navBarCont"
    class="position compa-pc-fixed-center"
    v-bind="$attrs"
    @click-left="onClickLeft"
  >
    <slot name="left" slot="left"></slot>
    <slot name="right" slot="right"></slot>
    <slot name="title" slot="title"></slot>
  </van-nav-bar>
</template>

<script>
import { NavBar } from 'vant'
import { insertStrutDomToBody } from '@/utils/index.js'

export default {
  components: { [NavBar.name]: NavBar },
  data () {
    return {}
  },
  computed: {},
  methods: {
    onClickLeft () {
      const { length } = history
      if (length <= 1) {
        this.$store.dispatch('tool/goHome', { isReplace: true })
      } else {
        history.go(-1)
      }
    },
    insertElementInBody () {
      const height = this.$refs.navBarCont?.$el?.clientHeight
      if (height) {
        this.strutElement = insertStrutDomToBody(height, {
          domPosition: 'before'
        })
      }
    }
  },
  created () {},
  mounted () {
    this.insertElementInBody()
  },
  destroyed () {
    const { strutElement } = this
    const parent = strutElement?.parentNode

    if (parent) {
      parent.removeChild(strutElement)
    }
  }
}
</script>
<style scoped lang="less">
@import url("~@/styles/common.less");

/deep/.van-nav-bar__content {
  background-color: var(--dp-bg-secondary);
  height: 100px;
  .van-nav-bar__left {
    // font-size: var(--dp-text-2xl);
    .van-nav-bar__text {
      .common-nav-bar-btn();
      margin-left: var(--dp-sp-xl);
      color: var(--dp-text-primary);
    }
  }
  .van-nav-bar__right {
    font-size: var(--dp-text-2xl);
  }
  .van-icon {
    font-size: var(--dp-text-5xl);
    margin-right: 0;
    color: var(--dp-text-primary);
  }
  .van-nav-bar__title {
    font-weight: normal;
    font-size: 30px;
    color: var(--dp-text-primary);
  }
}
.position {
  width: 750px;
  top: 0;
}
</style>
